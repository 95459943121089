<template>
    <div class="c3-bonus-card-list-item c3-bonus-card-list-item--is-static-item">
        <div class="c3-bonus-card-list-item-content-wrapper">
            <div class="c3-bonus-card-list-item-content">
                <h2 class="c3-bonus-card-list-item-title" v-html="$t('bonusCard.listItems.customer.title', {company: currentUser.company})"></h2>
                <div class="c3-bonus-card-list-item-text">{{$t('bonusCard.listItems.customer.message')}}</div>
                <router-link :to="{name:'BonusCardWizard'}" class="c3-bonus-card-list-item-button">
                    <c3-button :label="$t('bonusCard.listItems.customer.button')" styling="2"/>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import C3Button from '@/components/form/Button.vue';

    export default {
        components: {
            C3Button
        },
    };
</script>
<style lang="scss" scoped>
    .c3-bonus-card-list-item-content {
        position: static;
        padding: 30px 15px;
        transform: none;
    }
</style>
